// src/App.tsx
import React, { useState, useEffect } from 'react';
// import LoanCalculator from './components/LoanCalculator';
import { Container, AppBar, Toolbar, Typography, Button, Select, MenuItem, Box, CircularProgress } from '@mui/material';
import { auth, db } from './firebase';
import { signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import LoanCalculator from './components/LoanCalculator.tsx';
import './App.css';
import { LoanConfig, LoanData, getOrSetDefaultLoanConfig } from './components/loanUtil.ts';

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [loans, setLoans] = useState<LoanData[]>([]);
  const [selectedLoan, setSelectedLoan] = useState<number>(0);
  const [defaultLoanConfig, setDefaultLoanConfig] = useState<LoanConfig | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDefaultLoanConfig = async () => {
      const config = await getOrSetDefaultLoanConfig();
      setDefaultLoanConfig(config);
    };
    fetchDefaultLoanConfig();
  }, []);

  useEffect(() => {
    if (!defaultLoanConfig) {
      return;
    }
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        loadUserData(currentUser.uid);
      } else {
        setLoans(defaultLoanConfig.loans);
        setSelectedLoan(defaultLoanConfig.defaultLoan || 0);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLoanConfig]);

  const loadUserData = async (userId: string) => {
    if (!defaultLoanConfig) {
      return;
    }
    try {
      const docRef = doc(db, 'userLoanData', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data() as LoanConfig;
        setLoans(data.loans || []);
        setSelectedLoan(data.defaultLoan || 0);
        setIsLoading(false);
      } else {
        // Initialize with a default loan
        setLoans(defaultLoanConfig.loans);
        setSelectedLoan(defaultLoanConfig.defaultLoan || 0);
        setIsLoading(false);
        await setDoc(docRef, { loans: defaultLoanConfig.loans, defaultLoan: 0 } as LoanConfig);
      }
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  };

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleAddLoan = () => {
    if (!defaultLoanConfig) {
      return;
    }
    if (loans.length === 1 && !loans[0].name) {
      // Prompt user to name the first loan
      const name = prompt("Please name your first loan:", "First");
      setLoans([{ ...loans[0], name: name || 'First' }]);
      setSelectedLoan(0);
    }

    const newLoanName = prompt("Enter a name for the new loan:");
    if (newLoanName) {
      const newLoan: LoanData = {
        ...defaultLoanConfig.loans[0],
        name: newLoanName,
      };
      const newIndex = loans.length;
      setLoans([...loans, newLoan]);
      setSelectedLoan(newIndex);
    }
  };

  return (
    <div className="App">
      {/* <h1>Interactive Graph with Resizable Rectangles</h1> */}
      {/* <Graph /> */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Loan Calculator
            {!isLoading && loans.length <= 1 && <Button variant="contained" onClick={handleAddLoan}>
              {'Add Second Loan'}
            </Button>} 
          </Typography>
          {user ? (
            <>
              <Typography variant="body1" style={{ marginRight: '20px' }}>
                {user.displayName || user.email || '[Logged In]'}
              </Typography>
              <Button color="inherit" onClick={handleSignOut}>
                Sign Out
              </Button>
            </>
          ) : (
              <>
                <Typography variant="body1" style={{ marginRight: '20px' }}>
                  Guest
                </Typography>
                <Button color="inherit" onClick={handleSignIn}>
                  Sign In with Google
                </Button>
              </>
          )}
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: '20px', width: "100%", maxWidth: "100%" }}>
        {isLoading ? (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>) : loans.length > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Select
              value={selectedLoan}
                onChange={(e) => setSelectedLoan(Number(e.target.value) || 0)}
              displayEmpty
              style={{ minWidth: '200px' }}
            >
                {loans.map((loan, index) => (
                  <MenuItem key={loan.name} value={index}>{loan.name}</MenuItem>
              ))}
            </Select>
            <Button variant="contained" onClick={handleAddLoan}>
              {loans.length > 1 ? 'Add Loan' : 'Add Second Loan'}
            </Button>
          </Box>
        )}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : <LoanCalculator
          onLoanDataChange={(loanData: LoanData) => {
        // Update the loans array with the new loan data
              loans[selectedLoan] = { ...loans[selectedLoan], ...loanData };
              setLoans([...loans]); // if needed do a deep clone
            }}
        />}

      </Container>
    </div>
  );
}

export default App;
