import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import based on your project structure

export interface PrePayment {
  month: number;
  amount: number;
}
export interface InterestRateChange {
  interestRate: number;
  changedAtMonth: number;
  adjustEMI: boolean;
  isEnabled?: boolean;
}
export interface LoanData {
  name?: string | undefined | null;
  loanAmount: number;
  annualInterestRate: number;
  tenureInMonths: number;
  emi: number;
  interestRateChanges: InterestRateChange[];
  prePayments: PrePayment[];
  enablePrepayments: boolean;
  enableVariableInterestRate: boolean;
}

export interface LoanConfig {
  loans: LoanData[];
  defaultLoan?: number | null;
}

export const DEFAULT_LOAN_DATA: LoanData = {
  loanAmount: 38_00_000,
  annualInterestRate: 8.7,
  tenureInMonths: 120,
  emi: 50_000,
  interestRateChanges: [
    { changedAtMonth: 20, interestRate: 9.7, adjustEMI: true, isEnabled: true },
    { changedAtMonth: 40, interestRate: 10.7, adjustEMI: true, isEnabled: true },
  ],
  prePayments: [{ month: 2, amount: 1_00_000 }],
  enablePrepayments: true,
  enableVariableInterestRate: true,
};

export const getOrSetDefaultLoanConfig = async (): Promise<LoanConfig> => {
  const docRef = doc(db, 'appConfig', 'defaultLoan');

  const defaultConfig: LoanConfig = {
    loans: [DEFAULT_LOAN_DATA],
    defaultLoan: 0,
  };
  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() as LoanConfig;

      // If the data exists but is empty or invalid, set the default values
      if (!data.loans || data.loans.length === 0) {
        await setDoc(docRef, defaultConfig);
        return defaultConfig;
      }

      return data;
    } else {
      // If the document doesn't exist, create it with default values
      await setDoc(docRef, defaultConfig);
      return defaultConfig;
    }
  } catch (error) {
    console.error("Error accessing Firestore:", error);
    // In case of any error, return the default config without saving to Firestore
    return defaultConfig;
  }
};